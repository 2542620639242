import React, { useContext, useMemo } from "react";
import { useCallback } from "react"
import { ClockSvg } from "../../../assets/svg/Clock"
import moment from "moment"
import {PACKAGE_STATES} from '../../../constants/delivery'
import TrackingServices from "../../../services/TrackingServices";
import { useQuery } from "react-query";
import { Context } from "../../../context/StorageContext";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

export const PacakgeETA = ({pkgDetails})=>{
  const { trackingData } = useContext(Context);
  const path = useLocation();

  const { data } = useQuery({
    queryKey: ["journery", trackingData?.id],
    queryFn: () => {
      if (trackingData?.category === "agent") {
        return TrackingServices.getPackageNarrations(
          trackingData?.id,
          path?.pathname
        );
      } else if (trackingData?.category === "doorstep") {
        return TrackingServices.getDoorstepPackageNarrations(
          trackingData?.id,
          path?.pathname
        );
      } else if (
        trackingData?.category === "courier" ||
        trackingData?.category === "errand"
      ) {
        return TrackingServices.getErrandPackageNarrations(
          trackingData?.id,
          path?.pathname
        );
      } else if (trackingData?.category === "shelf") {
        return TrackingServices.getShelfPackageNarrations(
          trackingData?.id,
          path?.pathname
        );
      }
    },
    onError: (err) => {
      toast.error(
        "Package fetch error. " + err?.response?.data?.message ?? err?.message
      );
    },
  });
  
  const getPackageETA=useCallback(()=>{
    const state = pkgDetails?.state;
    const currentTime = new Date(data?.descriptions[data?.descriptions?.length - 1]?.time);
    const currentHour = currentTime.getHours();
    
    if (currentHour >= 21) {
      // It's past 9 PM
      return "The package is expected to arrive tomorrow.";
    }
      // It's before 9 PM
      switch (state) {
        case PACKAGE_STATES.onTransit:
          return `The package is expected to arrive at ${moment(currentTime).add(6, 'hours').format('hh:mm A')}.`;
        case PACKAGE_STATES.receivedWarehouse:
          return `The package is expected to arrive at ${moment(currentTime).add(4, 'hours').format('hh:mm A')}.`;
        case PACKAGE_STATES.assignedWarehouse:
          return `The package is expected to arrive at ${moment(currentTime).add(4, 'hours').format('hh:mm A')}.`;
        case PACKAGE_STATES.warehouseTransit:
          return `The package is expected to arrive at ${moment(currentTime).add(2, 'hours').format('hh:mm A')}.`;
        case PACKAGE_STATES.delivered:
          return `The package has arrived at its destination.`;
        case PACKAGE_STATES.collected:
          return `The package has arrived at its destination.`;
        default:
          return "The package is expected to arrive later today.";
      }
  
  }, [pkgDetails?.state])
    return (
        <div
      className={
        "p-4 rounded-2xl bg-white border border-gray-200 w-full flex flex-col "
      }
    >
        <div className="flex flex-row items-center my-1">
            <ClockSvg />
            <p className="mr-auto ml-2 font-bold">ETA</p>
</div>
<div
    className={`h-1 bg-primary my-1 rounded-xl w-full`}
  />

<div className={"flex space-x-1 my-1  mt-1 items-center"}>
          <span className={"text-sm"}>{getPackageETA()} </span>
        </div>

    </div>
    )
}